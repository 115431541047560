import { ArrowDown, ArrowUp } from '@local/web-design-system-2/dist/icons';
import Open from '@local/web-design-system-2/dist/icons/Open';
import {
    ButtonGroup,
    Button,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';

import { OBJECT_OPEN_IN } from 'src/strings';

interface OpenInExternalAppMenuProps {
    readonly options: Array<{
        readonly key: string;
        readonly ItemIcon: React.ElementType;
        readonly action: () => void;
    }>;
}

export function OpenInExternalAppMenu({ options }: OpenInExternalAppMenuProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isOpen = Boolean(anchorEl);
    const Icon = isOpen ? ArrowUp : ArrowDown;

    const [menuWidth, setMenuWidth] = React.useState(0);

    const openMenu = (event: React.MouseEvent<HTMLElement>) => {
        setMenuWidth(event.currentTarget.clientWidth);
        setAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <ButtonGroup
                automation-id="open-in-plot-buttons-view"
                color="secondary"
                orientation="horizontal"
                variant="outlined"
                onClick={openMenu}
                sx={(theme) => ({
                    height: '36px',
                    '&:hover .MuiButtonGroup-grouped, &:focus-within .MuiButtonGroup-grouped': {
                        backgroundColor: theme.palette.action.hover,
                    },
                })}
            >
                <Button
                    fullWidth
                    sx={{
                        minHeight: '36px',
                        maxHeight: '36px',
                    }}
                    startIcon={<Open sx={{ width: 24, height: 24 }} />}
                >
                    <Typography fontWeight={600} fontSize={13}>
                        {OBJECT_OPEN_IN}
                    </Typography>
                </Button>
                <Button
                    fullWidth
                    sx={{
                        width: '48px',
                        minHeight: '36px',
                        maxHeight: '36px',
                    }}
                >
                    <Icon fontSize="small" />
                </Button>
            </ButtonGroup>
            <Menu
                id="view-object-options-menu"
                anchorEl={anchorEl}
                open={isOpen}
                onClose={closeMenu}
                MenuListProps={{
                    sx: { width: menuWidth },
                }}
            >
                {options.map((option) => {
                    const { key, ItemIcon, action } = option;
                    return (
                        <MenuItem key={key} onClick={action}>
                            <ListItemIcon>
                                <ItemIcon />
                            </ListItemIcon>
                            <ListItemText>{key}</ListItemText>
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
}
