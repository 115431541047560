import { useMessagesContext } from '@local/messages-wds2/dist/MessagesContext';
import { trackError } from '@local/metrics/dist/src/metrics';
import File from '@local/web-design-system-2/dist/icons/File';
import Upload from '@local/web-design-system-2/dist/icons/Upload';
import { getOrgUuidFromParams } from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { hasRoleOrHigher } from '@local/workspaces/dist/utils/permissions';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { useCustomUploadFileByIdMutation } from 'src/apiClients/customFileEndpoints';
import { type DownloadFileResponse } from 'src/apiClients/GENERATED_fileClientEndpoints';
import { useStyles } from 'src/components/sidebar/Properties.styles';
import { PropertyDate } from 'src/components/sidebar/PropertyDate';
import { useWorkspaceContext } from 'src/contexts/WorkspaceContext';
import { useFileUploadContainer } from 'src/hooks/useFileUploadContainer';
import { FileActions } from 'src/pages/workspaceFilesPage/fileActions/FileActions';
import {
    CREATED_ON,
    FILE_HISTORY,
    INSUFFICIENT_WORKSPACE_PERMISSION,
    UPLOAD_NEW_VERSION,
} from 'src/strings';
import type { FileStatus } from 'src/types/files';
import { UploadStatus } from 'src/types/files';

interface Props {
    file?: DownloadFileResponse;
}

export const VersionHistory = ({ file }: Props) => {
    const { classes } = useStyles();
    const { addMessage } = useMessagesContext();
    const params = useParams();
    const [updateFile] = useCustomUploadFileByIdMutation();
    const { workspaceUserRole, workspaceId } = useWorkspaceContext();
    const uploadFieldRef = useRef<HTMLInputElement>(null);

    const { uploadingCount, upsertFileStatus } = useFileUploadContainer({ workspaceId });

    const isViewer = workspaceUserRole ? !hasRoleOrHigher(workspaceUserRole, 'editor') : false;

    if (!file?.versions) {
        return null;
    }

    const handleUploadPercentage = (percent: number, currentFileStatus: FileStatus) => {
        upsertFileStatus({
            ...currentFileStatus,
            percentCompleted: percent,
        });
    };

    const handleUploadFile = async (currentFileStatus: FileStatus, updatedVersion: File) => {
        const organisationId = getOrgUuidFromParams(params);
        const response = await updateFile({
            workspaceId,
            organisationId,
            fileId: file.file_id,
            updatedVersion: updatedVersion as File,
            updatePercentComplete: (percent) => handleUploadPercentage(percent, currentFileStatus),
        });
        upsertFileStatus({
            ...currentFileStatus,
            uploadStatus: 'error' in response ? UploadStatus.Failed : UploadStatus.Uploaded,
            percentCompleted: 100,
        });
        return response;
    };

    const handleError = async (
        error: FetchBaseQueryError | SerializedError,
        updatedFile: File,
        currentFileStatus: FileStatus,
    ) => {
        upsertFileStatus({
            ...currentFileStatus,
            uploadStatus: UploadStatus.Failed,
        });
        addMessage({
            message: ('message' in error ? error.message : String(error)) ?? '',
            severity: 'error',
        });
        trackError(`Error: ${error} uploading file "${updatedFile.name}"`);
        return Promise.reject(error);
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = event.currentTarget.files;
        if (!fileList || fileList.length < 1) {
            return;
        }

        const uploadPromises = Array.from(fileList).map(async (updatedFile) => {
            const currentFileStatus: FileStatus = {
                fileName: updatedFile.name,
                uploadStatus: UploadStatus.Uploading,
                size: updatedFile.size,
                // using a UUID here so that the fileId is unique for version when in the progress container
                // Without this the previous state of the container is overwritten
                fileId: uuid(),
                percentCompleted: 0,
            };

            upsertFileStatus(currentFileStatus);
            if (uploadFieldRef.current) {
                uploadFieldRef.current.value = '';
            }

            const response = await handleUploadFile(currentFileStatus, updatedFile);
            if (response.error) {
                return handleError(response.error, updatedFile, currentFileStatus);
            }
            return response.data;
        });

        await Promise.all(uploadPromises);
    };

    return (
        <Stack spacing={2} mt={5}>
            <Typography automation-id="file-history-label" variant="h5">
                {FILE_HISTORY}
            </Typography>
            <Divider />
            <Tooltip title={isViewer ? INSUFFICIENT_WORKSPACE_PERMISSION : ''}>
                <Button
                    component="label"
                    automation-id="upload-new-version-button"
                    variant="outlined"
                    fullWidth
                    size="large"
                    color="secondary"
                    disabled={uploadingCount > 0 || isViewer}
                    startIcon={<Upload />}
                >
                    <Typography variant="body1">{UPLOAD_NEW_VERSION}</Typography>
                    <input
                        ref={uploadFieldRef}
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                </Button>
            </Tooltip>

            <Box className={classes.versionHistoryList}>
                {file.versions.map((version) => (
                    <Box key={version.version_id} className={classes.version}>
                        <Grid direction="column" container>
                            <Box className={classes.versionHistory}>
                                <File />
                                <Box className={classes.versionHistoryItem}>
                                    <Typography
                                        sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                                        automation-id="version-history-file-name"
                                        title={file.name}
                                        variant="subtitle1"
                                    >
                                        {file.name}
                                    </Typography>
                                    <Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                gap: '8px',
                                            }}
                                        >
                                            <Typography
                                                sx={{ width: '70px' }}
                                                variant="body2"
                                                automation-id="version-history-id"
                                            >
                                                ID:
                                            </Typography>
                                            <Typography variant="body2" sx={{ width: '150px' }}>
                                                {version.version_id}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <Typography sx={{ width: '75px' }} variant="body2">
                                                {`${CREATED_ON}:`}
                                            </Typography>
                                            <PropertyDate
                                                timestamp={version.created_at}
                                                automationId="version-history-created-at"
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                <FileActions file={file} version={version} />
                            </Box>
                        </Grid>
                    </Box>
                ))}
            </Box>
        </Stack>
    );
};
