import type { FieldDefinition } from '@local/web-design-system-2/dist/components/GenericListing/types';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import * as React from 'react';

import { TableDragAndDrop } from 'src/components/dragAndDrop/TableDragAndDrop';

export interface TableHeaderProps {
    fields: FieldDefinition[];
    hasCheckbox?: boolean;
    hasActions?: boolean;
    hasDragAndDrop?: boolean;
}

export const TableHeader = ({
    fields,
    hasActions = false,
    hasCheckbox = false,
    hasDragAndDrop = false,
}: TableHeaderProps) => {
    const tableCellNodes: React.ReactNode[] = [];
    fields.forEach((fieldDefinition: FieldDefinition) => {
        const { key, label } = fieldDefinition;

        tableCellNodes.push(
            <TableCell key={key}>
                <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>{label}</Typography>
            </TableCell>,
        );
    });

    return (
        <>
            <TableHead>
                <TableRow>
                    {hasCheckbox && <TableCell />}
                    {tableCellNodes}
                    {hasActions && <TableCell />}
                </TableRow>
            </TableHead>
            {hasDragAndDrop ? <TableDragAndDrop colSpan={6} /> : null}
        </>
    );
};
