import { trackUserAction } from '@local/metrics';
import { ArrowDown } from '@local/web-design-system-2';
import type { User } from '@local/workspaces/dist/apiClients/GENERATED_workspaceClientEndpoints';
import { Popover, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { useEffect, useState } from 'react';

import { useObjectFilterParams } from 'src/hooks/useObjectFilterParams';
import { Categories, UserAction } from 'src/metrics.types';
import {
    APPLY_FILTERS,
    CLEAR_ALL,
    FILTER_OBJECTS_LABEL,
    FILTER_OBJECTS_PLACEHOLDER,
    FILTER_BY_DATA_TYPE,
    FILTER_BY_UPDATED_BY,
    FILTER_BY_UPDATED_ON,
    SEARCH_FOR_USERS,
    SEARCH_FOR_DATATYPES,
    UNKNOWN_USERNAME,
    SELECT_DATE,
} from 'src/strings';

import { SearchableCheckBoxGroup } from './SearchableCheckBoxGroup';

type DataType = {
    description: string;
    comparison: string[];
};

interface ObjectFilterMenuProps {
    userData: User[];
    isLoadingUsers: boolean;
    typeData: DataType[];
}

const UsersLoading = () => (
    <Grid container gap={2} marginBottom={2}>
        {Array.from({ length: 4 }, (_, index) => index).map((num) => (
            <Skeleton key={num} variant="rectangular" width="48%" height="42px" />
        ))}
    </Grid>
);

export const ObjectFilterMenu = ({ userData, isLoadingUsers, typeData }: ObjectFilterMenuProps) => {
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [, setIsDateRangePickerOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setIsDateRangePickerOpen(false);
        setAnchorEl(null);
    };

    const {
        applyFilters,
        cannotApplyFilters,
        cannotClearFilters,
        clearAllParams,
        type,
        updatedBy,
        setType,
        setUpdatedBy,
        setUpdatedOn,
        reset,
    } = useObjectFilterParams();

    const selectedTypeOptions = typeData.filter((option) => type.includes(option.description));
    const selectedUpdatedByOptions = userData.filter((option) =>
        updatedBy.includes(option.user_id),
    );

    const handleTypeChange = (options: DataType[]) =>
        setType(options.map((option) => option.description));
    const handleUpdateByChange = (options: User[]) =>
        setUpdatedBy(options.map((option) => option.user_id));

    const handleApply = () => {
        applyFilters();
        handleClose();
    };

    const handleStartDateChange = (date: Date | null) => {
        setStartDate(date);
        setUpdatedOn({ startDate: date || undefined, endDate: endDate || undefined });
    };

    const handleEndDateChange = (date: Date | null) => {
        setEndDate(date);
        setUpdatedOn({ startDate: startDate || undefined, endDate: date || undefined });
    };

    useEffect(() => {
        if (!open) {
            return;
        }

        trackUserAction(UserAction.USER_CLICKED_GO_DROPDOWN_FILTER, {
            category: Categories.GO_DROPDOWN_FILTER,
        });
        reset();
    }, [open]);

    const clearAll = () => {
        clearAllParams();
        setTypeSearch('');
        setUpdatedBySearch('');
        setStartDate(null);
        setEndDate(null);
    };

    const [typeSearch, setTypeSearch] = useState('');
    const [updatedBySearch, setUpdatedBySearch] = useState('');
    const disableClearFilters = cannotClearFilters && typeSearch === '' && updatedBySearch === '';
    const rotateDeg = open ? 180 : 0;
    return (
        <>
            <Button
                variant="outlined"
                color="secondary"
                onClick={handleClick}
                automation-id="dropdown-container-filter-objects"
                sx={{ height: '40px', minWidth: '220px', textAlign: 'left' }}
            >
                <Typography
                    variant="caption"
                    sx={{ position: 'absolute', top: -10, left: 8, bgcolor: 'Background', px: 0.6 }}
                >
                    {FILTER_OBJECTS_LABEL}
                </Typography>
                <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ width: '100%' }}
                    justifyContent="space-between"
                >
                    <Typography>{FILTER_OBJECTS_PLACEHOLDER}</Typography>
                    <ArrowDown
                        sx={{
                            transform: `rotate(${rotateDeg}deg)`,
                            transition: 'transform 0.2s ease',
                        }}
                    />
                </Stack>
            </Button>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Stack
                    spacing={3}
                    padding={3}
                    direction="column"
                    sx={{ width: '450px', maxWidth: '450px' }}
                >
                    <Stack spacing={1} useFlexGap>
                        <Typography variant="h6" gutterBottom automation-id="filter-by-data-type">
                            {FILTER_BY_DATA_TYPE}
                        </Typography>
                        <SearchableCheckBoxGroup
                            placeholder={SEARCH_FOR_DATATYPES}
                            options={typeData}
                            handleChange={handleTypeChange}
                            selectedOptions={selectedTypeOptions}
                            getOptionLabel={(option) => option.description}
                            getOptionKey={(option) => option.description}
                            value={typeSearch}
                            setValue={setTypeSearch}
                        />
                    </Stack>

                    <Stack spacing={1} useFlexGap>
                        <Typography variant="h6" gutterBottom automation-id="filter-by-updated-on">
                            {FILTER_BY_UPDATED_ON}
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Stack direction="row" spacing={2}>
                                <DatePicker
                                    label={SELECT_DATE}
                                    value={startDate || null}
                                    onChange={handleStartDateChange}
                                    maxDate={endDate || undefined}
                                    slotProps={{
                                        textField: {
                                            size: 'small',
                                            inputProps: {
                                                'automation-id': 'start-date-picker',
                                            },
                                        },
                                    }}
                                />
                                <DatePicker
                                    label={SELECT_DATE}
                                    value={endDate || null}
                                    onChange={handleEndDateChange}
                                    minDate={startDate || undefined}
                                    slotProps={{
                                        textField: {
                                            size: 'small',
                                            inputProps: {
                                                'automation-id': 'end-date-picker',
                                            },
                                        },
                                    }}
                                />
                            </Stack>
                        </LocalizationProvider>
                    </Stack>

                    <Stack spacing={1} useFlexGap>
                        <Typography variant="h6" gutterBottom automation-id="filter-by-updated-by">
                            {FILTER_BY_UPDATED_BY}
                        </Typography>
                        {isLoadingUsers ? (
                            <UsersLoading />
                        ) : (
                            <SearchableCheckBoxGroup
                                placeholder={SEARCH_FOR_USERS}
                                options={userData}
                                handleChange={handleUpdateByChange}
                                selectedOptions={selectedUpdatedByOptions}
                                getOptionLabel={(option) => option.full_name || UNKNOWN_USERNAME}
                                getOptionKey={(option) => option.user_id}
                                value={updatedBySearch}
                                setValue={setUpdatedBySearch}
                            />
                        )}
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <Button
                            size="large"
                            onClick={clearAll}
                            disabled={disableClearFilters}
                            color="secondary"
                            variant="outlined"
                            automation-id="go-dropdown-filter-clear-all-button"
                            fullWidth
                        >
                            {CLEAR_ALL}
                        </Button>
                        <Button
                            size="large"
                            color="primary"
                            variant="contained"
                            onClick={handleApply}
                            disabled={cannotApplyFilters}
                            automation-id="go-dropdown-filter-apply-button"
                            fullWidth
                        >
                            {APPLY_FILTERS}
                        </Button>
                    </Stack>
                </Stack>
            </Popover>
        </>
    );
};
