import ListViewIcon from '@local/web-design-system-2/dist/icons/List';
import StorageIcon from '@local/web-design-system-2/dist/icons/Storage';
import { useListUserRolesQuery } from '@local/workspaces/dist/apiClients/enhancedWorkspaceMiddleware';
import {
    getHubUrlForCurrentOrg,
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import {
    CategoriesWorkspace,
    UserActionWorkspace,
} from '@local/workspaces/dist/WorkspaceMetrics.types';
import { Stack, useTheme, Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { useFlags } from 'launchdarkly-react-client-sdk';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { SearchField } from 'src/components/searchField/SearchField';
import { useObjectSearchParams } from 'src/hooks/useObjectSearchParams';
import { filterTypeMetaData } from 'src/mockApi/mockFilterDataTypes';
import { AffinityFolderViews } from 'src/pages/workspacePage/affinityFoldersContent/AffinityFolderViews';
import {
    setWorkspaceContentMode,
    workspaceContentModeSelector,
} from 'src/store/features/displaySettingSlice';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { ENTER_OBJECT_NAME, SEARCH_OBJECTS } from 'src/strings';

import { ObjectFilterMenu } from './objectFilterMenu/ObjectFilterMenu';
import { ObjectFilterPills } from './objectFilterMenu/ObjectFilterPills';

const ViewModeButtons = () => {
    const dispatch = useAppDispatch();
    const workspaceContentMode = useAppSelector(workspaceContentModeSelector);
    const { evouiAffinityFolders } = useFlags();
    const theme = useTheme();

    const selectedButtonSx = {
        color: theme.palette.primary.main,
    };
    const unselectedButtonSx = {
        color: theme.palette.text.primary,
    };
    return (
        <Stack direction="row" sx={{ paddingRight: '8px' }}>
            <IconButton
                onClick={() => {
                    dispatch(setWorkspaceContentMode('path'));
                }}
                size="large"
                automation-id="path-view"
                sx={workspaceContentMode === 'path' ? selectedButtonSx : unselectedButtonSx}
            >
                <ListViewIcon fontSize="small" />
            </IconButton>
            {evouiAffinityFolders && (
                <IconButton
                    onClick={() => {
                        dispatch(setWorkspaceContentMode('affinity-folders'));
                    }}
                    size="large"
                    sx={
                        workspaceContentMode === 'affinity-folders'
                            ? selectedButtonSx
                            : unselectedButtonSx
                    }
                    automation-id="affinity-folder-view"
                >
                    <StorageIcon fontSize="small" />
                </IconButton>
            )}
        </Stack>
    );
};

export function ContentOptions({ paginationControls }: { paginationControls?: ReactNode }) {
    const { evouiAffinityFolders } = useFlags();
    const workspaceContentMode = useAppSelector(workspaceContentModeSelector);

    const showFilterDropdown = evouiAffinityFolders && workspaceContentMode === 'path';

    const params = useParams();

    const userListResponse = useListUserRolesQuery({
        orgId: getOrgUuidFromParams(params),
        workspaceId: getSelectedWorkspaceFromParams(params),
        hubUrl: getHubUrlForCurrentOrg(),
    });
    const { data, isLoading } = useMemo(
        () =>
            showFilterDropdown
                ? userListResponse
                : { data: { results: undefined }, isLoading: false },
        [showFilterDropdown, userListResponse],
    );
    const userData = data?.results || [];

    const { search, setSearch } = useObjectSearchParams();

    return (
        <>
            <Grid
                container
                columns={{ xs: 1, md: 2 }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap={2}
            >
                <ViewModeButtons />
                <Divider orientation="vertical" flexItem sx={{ borderRightWidth: 1.5, my: 1.5 }} />
                <Stack direction="row" flexGrow={1} sx={{ paddingLeft: '16px' }}>
                    {paginationControls}
                    {evouiAffinityFolders && workspaceContentMode === 'affinity-folders' && (
                        <AffinityFolderViews />
                    )}
                </Stack>
                {showFilterDropdown && (
                    <Grid alignItems="center">
                        <Stack direction="row" spacing={2}>
                            <ObjectFilterMenu
                                userData={userData}
                                isLoadingUsers={isLoading}
                                typeData={filterTypeMetaData.options}
                            />
                            <SearchField
                                color="secondary"
                                variant="outlined"
                                defaultValue={search}
                                sx={{ maxWidth: '220px' }}
                                onSearchCallBack={setSearch}
                                placeholder={ENTER_OBJECT_NAME}
                                automation-id="content-options-search"
                                InputProps={{ sx: { maxHeight: '40px' } }}
                                userAction={UserActionWorkspace.WORKSPACE_OBJECTS_SEARCH}
                                userActionCategory={CategoriesWorkspace.WORKSPACE_OBJECTS_PAGE}
                                label={SEARCH_OBJECTS}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Stack>
                    </Grid>
                )}
            </Grid>
            {showFilterDropdown && <ObjectFilterPills userData={userData} isLoading={isLoading} />}
        </>
    );
}
