import type { GetObjectResponse } from '@api/goose/dist/enhancedGooseClient';
import { extractObjectSchemaName } from '@api/goose/dist/utils/extractObjectSchemaName';
import { KeyValueInfoTable } from '@local/web-design-system-2/dist/components/KeyValueInfoTable/KeyValueInfoTable';
import type { Folder } from '@local/workspaces/dist/apiClients/workspaceClientEndpoints';
import { Divider, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';

import { OpenInExternalAppOptions } from 'src/components/sidebar/OpenInExternalAppOptions';
import { OpenInViewerButton } from 'src/components/sidebar/OpenInViewerButton';
import { PropertyDate } from 'src/components/sidebar/PropertyDate';
import { FilterProperties } from 'src/pages/workspacePage/workspaceContent/sidebar/FilterProperties';
import {
    CREATED_BY,
    CREATED_ON,
    DATA_TYPE,
    OBJECT_ID,
    OBJECT_PATH,
    PROPERTIES,
    SOURCE,
    VERSION_ID,
} from 'src/strings';

import { Icon } from './Icon';

export function NameValueItem({ name, children }: React.PropsWithChildren<{ name: string }>) {
    return (
        <>
            <Typography variant="body2" noWrap>
                {name}:
            </Typography>
            {children}
        </>
    );
}

interface Props {
    isFile: boolean;
    data?: GetObjectResponse;
    selectedFilter?: Folder;
}

export const Properties = ({ isFile, data, selectedFilter }: Props) => {
    if (!isFile) {
        // Show filter properties
        return <FilterProperties data={selectedFilter} />;
    }

    if (!data) {
        return null;
    }

    return (
        <Stack spacing={2}>
            <Typography variant="body1">{PROPERTIES}</Typography>
            <Divider />
            <Stack alignItems="center" direction="row" spacing={1}>
                <Icon schema={data.object.schema} />
                <Typography
                    automation-id="object-name"
                    variant="subtitle1"
                    title={data.object.name}
                >
                    {data.object.name}
                </Typography>
            </Stack>
            <Stack sx={{ paddingBottom: '16px' }}>
                <KeyValueInfoTable
                    sx={{
                        rowGap: '4px',
                        columnGap: '8px',
                        wordWrap: 'break-word',
                        display: 'inline-grid',
                        wordBreak: 'break-all',
                        overflowWrap: 'break-word',
                    }}
                    items={[
                        {
                            label: DATA_TYPE,
                            value: (
                                <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
                                    {extractObjectSchemaName(data.object.schema)}
                                </Typography>
                            ),
                        },
                        {
                            label: OBJECT_PATH,
                            value: data.object_path,
                        },
                        {
                            label: SOURCE,
                            value: data.object.source,
                        },
                        {
                            label: VERSION_ID,
                            value: data.version_id,
                        },
                        {
                            label: OBJECT_ID,
                            value: data.object_id,
                        },
                        {
                            label: CREATED_ON,
                            value: <PropertyDate timestamp={data.created_at} />,
                        },
                        {
                            label: CREATED_BY,
                            value: data.created_by?.name,
                        },
                        ...(data.object.tags
                            ? Object.keys(data.object.tags).map((key) => ({
                                  label: key,
                                  value: data.object.tags[key],
                              }))
                            : []),
                    ]}
                />
            </Stack>
            <Stack direction="column" spacing={3}>
                <OpenInViewerButton objectSchema={data.object.schema} />
                <OpenInExternalAppOptions objectDetail={data} />
            </Stack>
        </Stack>
    );
};
