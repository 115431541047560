import { useSidebarContext } from '@local/content-area/dist/Sidebar/SidebarContext';
import { trackUserAction } from '@local/metrics/dist/src/metrics';
import type { Schema } from '@local/workspaces/dist/apiClients/workspaceClientEndpoints';
import { Box } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import type { SelectChangeEvent } from '@mui/material/Select';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';

import { Categories, UserAction } from 'src/metrics.types';
import { VIEW } from 'src/strings';

interface DropDownProps {
    schemas: Schema[];
    selected: string;
    setSelectedSchema: (key: string) => void;
    isLoading: boolean;
}

export const DropDown = ({ schemas, selected, setSelectedSchema, isLoading }: DropDownProps) => {
    const { setSidebarContent } = useSidebarContext();
    const handleChange = (event: SelectChangeEvent) => {
        setSidebarContent(undefined);
        setSelectedSchema(event.target.value as string);
        trackUserAction(UserAction.USER_TOGGLED_AFFINITIES_VIEW, {
            category: Categories.AFFINITY_SCHEMA,
        });
    };

    if (isLoading) {
        return <Skeleton animation="wave" variant="rectangular" width={210} height={60} />;
    }
    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{VIEW}</InputLabel>
                <Select
                    automation-id="select-menu-item-label"
                    labelId="view-by-select-label"
                    label={VIEW}
                    onChange={handleChange}
                    value={selected}
                    variant="outlined"
                    size="small"
                    sx={{ width: 220 }}
                >
                    {schemas.map((schema) => (
                        <MenuItem
                            key={schema.name}
                            value={schema.name}
                            automation-id={`select-menu-menu-item-${schema.name}`}
                        >
                            {schema.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};
