import { Button, Typography } from '@mui/material';
import React from 'react';

interface OpenInExternalAppButtonProps {
    readonly action: () => void;
    readonly label: string;
    readonly ItemIcon: React.ElementType;
}

export function OpenInExternalAppButton({ action, label, ItemIcon }: OpenInExternalAppButtonProps) {
    return (
        <Button
            automation-id="single-option-button"
            onClick={action}
            variant="outlined"
            color="secondary"
            sx={{
                width: '100%',
                minHeight: '36px',
                maxHeight: '36px',
            }}
            startIcon={<ItemIcon sx={{ width: 24, height: 24 }} />}
        >
            <Typography fontWeight={600} fontSize={13}>
                {label}
            </Typography>
        </Button>
    );
}
